import React from 'react'
import type { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import SparseLayout from '../layouts/Sparse'
import PageWithLayoutType from '../types/pageWithLayout'

const HomePage: React.FunctionComponent = () => {
  const { t } = useTranslation('lobby')
  return <>{t('home')}</>
}

;(HomePage as PageWithLayoutType).layout = SparseLayout

export const getServerSideProps: GetServerSideProps = async ({ locale = 'en-US' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['lobby'])),
    },
  }
}

export default HomePage
